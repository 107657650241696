<script>
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'

export default {
  name: 'base-alert',
  components: {
    BaseButton,
    BaseIcon,
  },
  props: {
    look: {
      type: String,
      default: 'filled',
      validator(value) {
        return /(filled|light-filled|light)$/.test(value)
      },
    },
    type: {
      type: String,
      default: 'info',
      validator(value) {
        return /(info|warning|success|error)$/.test(value)
      },
    },
    title: {
      type: String,
      default: '',
    },
    icon: {
      type: Boolean,
      default: true,
    },
    closable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    iconName() {
      switch (this.type) {
        case 'info':
          return 'plain/info'
        case 'warning':
        case 'error':
          return 'plain/alert-circle'
        case 'success':
          return 'heroicons/solid/check-circle'
      }

      return ''
    },
  },
}
</script>

<template>
  <div
    v-if="look == 'light'"
    class="relative flex w-full items-center overflow-hidden p-[15px] text-sm sm:rounded-lg"
    :class="{
      'border-warning bg-warning text-fg-warning': type === 'warning',
      'border-error bg-error text-fg-error': type === 'error',
      'border-success bg-success text-fg-success': type === 'success',
      'border-info bg-info text-fg-info': type === 'info',
    }"
  >
    <div
      v-if="icon"
      class="mr-[15px] flex w-[30px] items-center justify-center text-center"
      :class="{
        'text-fg-warning': type === 'warning',
        'text-fg-error': type === 'error',
        'text-fg-success': type === 'success',
        'text-fg-info': type === 'info',
      }"
    >
      <base-icon :svg="iconName" class="block" :size="26" />
    </div>
    <div class="z-1 w-full pr-2.5 text-base leading-tight text-inherit">
      <div v-if="title" class="mb-[5px] font-bold">
        {{ title }}
      </div>
      <span class="font-bold">
        <slot />
      </span>
    </div>
    <base-button
      v-if="closable"
      type="button"
      icon="plain/close"
      color="inherit"
      @click="$emit('close')"
    />
  </div>
  <div
    v-else
    class="relative flex w-full items-start overflow-hidden p-[15px] text-sm sm:rounded-lg"
    :class="[
      look === 'filled' && 'text-white',
      type === 'info' && (look === 'filled' ? 'bg-fg-info' : 'bg-info'),
      type === 'error' && (look === 'filled' ? 'bg-fg-error' : 'bg-error'),
      type === 'warning' &&
        (look === 'filled' ? 'bg-fg-warning' : 'bg-warning'),
      type === 'success' &&
        (look === 'filled' ? 'bg-fg-success' : 'bg-success'),
    ]"
  >
    <div
      v-if="icon"
      class="mr-[15px] flex w-5 items-center justify-center text-center text-inherit"
      :class="[
        look !== 'filled' && type === 'warning' && 'text-fg-warning',
        look !== 'filled' && type === 'error' && 'text-fg-error',
        look !== 'filled' && type === 'success' && 'text-fg-success',
        look !== 'filled' && type === 'info' && 'text-fg-info',
      ]"
    >
      <base-icon :svg="iconName" size="md" class="block" />
    </div>
    <div class="z-1 w-full pr-2.5 text-base leading-tight text-inherit">
      <div v-if="title" class="mb-[5px] font-bold">
        {{ title }}
      </div>
      <span class="text-sm">
        <slot />
      </span>
    </div>
    <base-button
      v-if="closable"
      type="button"
      icon="plain/close"
      icon-size="sm"
      size="sm"
      color="inherit"
      @click="$emit('close')"
    />
  </div>
</template>
